export enum BREAKPOINT {
  SM = 360,
  MD = 768,
  LG = 1024,
  SIDE_NAV = 960,
}

export const NAV_HEIGHT = 88

export const ARTICLES_PER_PAGE = 8

export const NEW_PILL_NAMES = {
  webinar: 'webinars',
  'case-study': 'case_studies',
  publication: 'publications',
  report: 'reports',
  blog: 'blog',
  'patient-story': 'patient_stories',
}
